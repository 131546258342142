import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner02.png'
import img01 from '../../assets/img/Recruit04/Recruit04_01.png'
import img02 from '../../assets/img/Recruit04/must.png'
import img03 from '../../assets/img/Recruit04/Recruit04_03.png'
import img04 from '../../assets/img/Recruit04/Recruit04_04.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '15px',
      width: '35px',
    },
    td1: {
      width: '20%',
      border: '1px solid #d3d3d3',
      fontWeight: 'bold',
      borderTop: '1px solid #d3d3d3',
      borderBottom: '1px solid #d3d3d3',
      fontWeight: 'bold',
      //   borderImage: 'linear-gradient(to right, #0083c5 0%, #0083c5 33%, #ec4a26 66%, #ec4a26 100%)',
    },
    td2: {
      border: '1px solid #d3d3d3',
      fontSize: '12px',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '5px',
      paddingRight: '5px',
      fontWeight: 'bold',
    },
    span: {
      fontColor: '#d3d3d3',
      align: 'center',
    },
    // tableSize: {
    //   borderCollapse: 'collapse',
    //   paddingTop:'-30%',
    //   width: '100%',
    //   height: '110%',
    //   border: '1px solid #ffffff',
    // },
    tableCss: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      paddingLeft: '30px',
      height: '60px',
      border: '1px solid #ffffff',
      fontWeight: 'bold',
    },
    tableTitle: {
      borderCollapse: 'collapse',
      fontSize: '30px',
      width: '18%',
      fontFamily: 'Noto Sans JP',
      color: '#008ad7',
      fontWeight: 'bold',
      paddingLeft: '110px',
      paddingBottom: '30px',
      fontWeight: 'bold',
    },
    td01: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      paddingLeft: '25px',
      width: '20%',
      fontFamily: 'Noto Sans JP',
    },
    td02: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      width: '13%',
      fontFamily: 'Noto Sans JP',
    },
    td03: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      width: '20%',
      fontFamily: 'Noto Sans JP',
    },
    td04: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      width: '5%',
      fontFamily: 'Noto Sans JP',
    },
    td05: {
      borderCollapse: 'collapse',
      fontSize: '12px',
      width: '5%',
      fontFamily: 'Noto Sans JP',
    },
    textbox: {
      height: '30px',
      width: '370px',
      marginLeft: '-30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
    },
    textboxS: {
      height: '30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
    },
    textboxL: {
      width: '370px',
      height: '100px',
      marginLeft: '-30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        {/* <script src="css/assets/jss/jquery.mCustomScrollbar.css"></script> */}
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h3 style={style.margin}>
          {' '}
          信頼と肯定を誇り持って、価格創出を追求する企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　採用情報</dt>
                <dd>
                  <a href="/recruit01">インフラ</a>
                </dd>
                <dd>
                  <a href="/recruit02">無線通信</a>
                </dd>
                <dd>
                  <a href="/recruit03">開発</a>
                </dd>
                <dd>
                  <a href="/contact">応募登録</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <div className="item-wrapper">
                  <img src={img01} className="recruit04Img01" />
                </div>
                <div className="item-info-box ">
                  <table className="tableSize">
                    <tr style={style.tableCss}>
                      <td style={style.tableTitle} align="left" colSpan="8">
                        ○　フォームからの応募　○
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        応募別
                      </td>
                      <td style={style.td02} align="left">
                        <img src={img02} style={style.imgSize}></img>
                      </td>
                      <td style={style.td03} align="left" colSpan="6">
                        <input type="checkbox" name="work" value="work01" />
                        　開発
                        <span>　　　</span>
                        <input type="checkbox" name="work" value="work02" />
                        　ネットワーク
                        <span>　　</span>
                        <input type="checkbox" name="work" value="work03" />
                        　モバイル開発
                        <span>　　　　　　</span>
                        <input type="checkbox" name="work" value="work04" />
                        　システム
                        <span>　</span>
                        <input type="checkbox" name="work" value="work05" />
                        　データベース
                        <span>　　</span>
                        <input type="checkbox" name="work" value="work06" />
                        　その他
                        <span>　</span>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        氏名
                      </td>
                      <td style={style.td02} align="left">
                        <img src={img02} style={style.imgSize}></img>
                      </td>
                      <td style={style.td04} align="left" colSpan="1">
                        姓
                      </td>
                      <td style={style.td05} align="left" colSpan="2">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textboxS}
                        ></input>
                      </td>
                      <td style={style.td04} align="left" colSpan="1">
                        　名
                      </td>
                      <td style={style.td05} align="left" colSpan="2">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textboxS}
                        ></input>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        氏名・フリガナ
                      </td>
                      <td style={style.td02} align="left">
                        <img src={img02} style={style.imgSize}></img>
                      </td>
                      <td style={style.td04} align="left" colSpan="1">
                        姓
                      </td>
                      <td style={style.td05} align="left" colSpan="2">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textboxS}
                        ></input>
                      </td>
                      <td style={style.td04} align="left" colSpan="1">
                        　名
                      </td>
                      <td style={style.td05} align="left" colSpan="2">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textboxS}
                        ></input>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        メールアドレス
                      </td>
                      <td style={style.td02} align="left">
                        <img src={img02} style={style.imgSize}></img>
                      </td>
                      <td style={style.tableCss} align="left" colSpan="6">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textbox}
                        ></input>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        電話番号
                      </td>
                      <td style={style.td02} align="left">
                        <img src={img02} style={style.imgSize}></img>
                      </td>
                      <td style={style.tableCss} align="left" colSpan="6">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textbox}
                        ></input>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        お問い合わせ内容
                      </td>
                      <td style={style.td02} align="left">
                        　　
                      </td>
                      <td style={style.tableCss} align="left" colSpan="6">
                        <input
                          type="text"
                          name="lastname"
                          style={style.textboxL}
                        ></input>
                      </td>
                    </tr>
                    <tr style={style.tableCss}>
                      <td style={style.td01} align="left">
                        添付ファイル
                      </td>
                      <td style={style.td02} align="left">
                        　　
                      </td>
                      <td style={style.tableCss} align="left" colSpan="6">
                        <input type="file" name="file" accept="image/png" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
             

            </div>
            
          </div>
          
        </div>
        
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
